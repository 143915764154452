<template>
  <SubPageWrapper empty-cols>
    <template #loader>
      <SlOverlay :show="isLoading" />
    </template>
    <template #modal>
      <ConfirmModal
        :id="modalIds.CONFIRM"
        without-cancel
        width="400"
      />
      <NotifyModal :id="modalIds.NOTIFY" />
    </template>
    <template #left-col>
      <SlTabList
        :value="$sl_routeName"
        :tabs="projectTabs"
      />
      <div
        v-if="isRamStatsVisible"
        class="ram-stats"
      >
        <div class="ram-stats__row">
          <span>
            {{ $t('Web.RAMUsage.Current') }}
          </span>
          <span>
            {{ getReadableRam(ramStats.currentProcessRAM) }}&nbsp;({{ usagePercent }}%)
          </span>
        </div>
        <div class="ram-stats__row">
          <span>
            {{ $t('Web.RAMUsage.Peak') }}
          </span>
          <span>
            {{ getReadableRam(ramStats.peakProcessRAM) }}
          </span>
        </div>
        <div class="ram-stats__row">
          <span>
            {{ $t('Web.RAMUsage.Total') }}
          </span>
          <span>
            {{ getReadableRam(ramStats.totalRAM) }}
          </span>
        </div>
      </div>
    </template>
    <router-view />
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ConfirmModal from '@/components/Modals/Shared/ConfirmModal.vue';
import NotifyModal from '@/components/Modals/Shared/NotifyModal.vue';
import modalIds from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';
import { projectTabsConfig } from '@/config/project';
import { sendRequests } from '@/helpers/lastChange/sendRequests.js';

export default {
  name: 'ProjectsPage',
  components: {
    ConfirmModal,
    NotifyModal
  },
  data() {
    return {
      modalIds,
      isFirstLoading: false,
      ramInterval: null,
      RAM_INTERVAL_TIMEOUT: 10000
    };
  },
  computed: {
    ...mapState({
      isActionLoading: (state) => state.manageProjects.isLoading,
      ramStats: (state) => state.project.ram_stats
    }),
    ...mapGetters({
      hasProjectsList: 'manageProjects/hasProjectsList',
      isFreePlan: 'initialization/isFreePlan'
    }),
    isRamStatsVisible() {
      return this.ramStats && !this.isFreePlan;
    },
    projectTabs() {
      return projectTabsConfig(this).filter(tab => {
        if (tab.value === routeNames.ARCHIVE) {
          return this.$sl_isUserAdmin && !this.isFreePlan;
        }

        return true;
      });
    },
    isLoading() {
      return (this.isFirstLoading && !this.hasProjectsList) || this.isActionLoading;
    },
    usagePercent() {
      return (this.ramStats.currentProcessRAM / this.ramStats.totalRAM * 100).toFixed(2);
    }
  },
  watch: {
    $sl_routeName(name) {
      sendRequests(name, { cache: true });
    }
  },
  async beforeMount() {
    if (!this.isFreePlan) {
      this.ramInterval = setInterval(this.fetchRamStats, this.RAM_INTERVAL_TIMEOUT);
    }

    try {
      this.isFirstLoading = true;

      await sendRequests(this.$sl_routeName, { cache: true });
    } finally {
      this.isFirstLoading = false;
    }
  },
  beforeDestroy() {
    clearInterval(this.ramInterval);
  },
  methods: {
    ...mapActions({
      fetchRamStats: 'project/fetchRamStats'
    }),
    getReadableRam(mb) {
      if (!mb) {
        return this.$t('Web.RAMUsage.Calculation');
      }

      const GB = mb / 1024;

      return `${GB.toFixed(2)} ${this.$t('Web.FileSizes.Gb')}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/color";
@import "@/style/utils/mixins";

::v-deep .content-block {
  .subpage__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 32px;
    margin-bottom: 20px;

    &-actions {
      display: flex;
      column-gap: 16px;
    }
  }

  .subpage__actions {
    justify-content: flex-start;
    padding: 1px 0 0 1px;
  }

  .subpage__table-wrapper {
    height: calc(100% - 100px);

    &.subpage__table-wrapper--alert {
      height: calc(100% - 112px);
    }
  }

  .subpage__table {
    height: calc(100% - 54px);
  }

  .sl-alert {
    margin-bottom: 16px;
  }

  .sl-table {
    max-width: 100%;
  }

  .project-list__cell {
    &--filter {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    &--status {
      display: block;
      width: fit-content;
      padding: 4px 8px;
      margin-left: 8px;
      border-radius: 16px;
      @include body-3-md;
    }

    &--active {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      height: 100%;
      width: 3px;
      background-color: $accent-green-70;
    }
  }
}

.ram-stats {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: auto;

  &__row {
    display: flex;
    align-items: center;
    gap: 4px;

    @include body-3;
    color: $grey-60;
  }
}
</style>
